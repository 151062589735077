import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import useWindowSize from '../hooks/useWindowSize'
import Pattern from '../images/tuya-pattern-menu.inline.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

// Styles
import '../styles/pages/reservation.scss'

const ReservationPage = ({ location }) => {
  const { t } = useTranslation()
  return (
    <Layout className="reservation-page" pathname={location.pathname} alwaysBlack simpleHeader>
      <SEO title={t('reservation')}></SEO>
      <section className="hero-section flex items-end px-6 pb-7 md:pb-16 md:px-20 lg:px-32 ">
        <div className="2xl:max-w-screen-xl 2xl:mx-auto w-full">
          <h1 className="text-tuya-rho">
            <Trans>reservation</Trans>
          </h1>
        </div>
      </section>
      <section className="main-section">
        <div className="w-[100%] h-[805px]">
          <div id="quandoo-booking-widget"></div>
          <script
            src="https://booking-widget.quandoo.com/index.js"
            data-merchant-id="58751"
            data-theme="light"
            data-primary-color="1870C3"
          ></script>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query ReservationQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default ReservationPage
